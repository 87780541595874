<template>
    <div v-if="!loading" class="rf-flex rf-flex-col rf-w-full rf-max-w-[1140px] rf-mx-auto rf-py-10 rf-gap-4">
      <div class="rf-text-[32px] rf-text-[#0f4379] rf-font-medium rf-mb-5">
        <span v-if="todayData">Files Currently Under Processing by Our Engineers</span>
        <span v-else>Yesterday processed files</span>
      </div>
      <div v-for="(vehicle, index) in vehicles" :key="index" class="rf-relative rf-mb-7">
        <div
          class="rf-grid screen1100:rf-grid-cols-[75px_410px_1fr] rf-grid-rows-[1fr] screen1100:rf-w-full screen1100:rf-max-w-full rf-relative rf-z-20 rf-max-w-[650px] rf-mx-auto rf-bg-[#ffffff] rf-shadow-md rf-rounded-xl rf-items-center rf-pb-1 screen1100:rf-pb-0">
          <div class="rf-flex rf-justify-center screen1100:rf-justify-normal rf-py-1 screen1100:rf-py-2 rf-px-3 rf-border-r">
            <img :src="getBrandImageUrl(vehicle.vehicle_data.brand)" :alt="vehicle.vehicle_data.brand" class="rf-w-[100px] rf-w-h-[100px] screen1100:rf-w-[50px] screen1100:rf-w-h-[50px]" />
          </div>
          <p class="rf-flex rf-justify-center screen1100:rf-justify-normal rf-py-2 rf-px-3 rf-text-lg rf-font-bold rf-text-[#0f4379] rf-border-r">
            {{ getCombinedVehicleName(vehicle) }} / {{ vehicle.vehicle_data.year }}
          </p>
          <div class="rf-grid rf-grid-cols-[min-content fr] screen768:rf-grid-cols-[80px_min-content_auto] rf-grid-rows-[1fr] rf-justify-center screen1100:rf-justify-normal rf-gap-2 rf-py-2 rf-px-3">
            <div class="rf-flex rf-flex-row rf-rounded-md rf-pr-1">
              <p class="rf-bg-[#0f4379] rf-px-1 rf-rounded-l-md rf-text-white rf-mr-4">HP</p>
              <p class="item-cell rf-relative rf-font-semibold">{{ vehicle.vehicle_data.hp }}</p>
            </div>
            <div class="rf-flex rf-flex-row rf-rounded-md rf-pr-1">
              <p class="rf-bg-[#0f4379] rf-px-1 rf-rounded-l-md rf-text-white rf-mr-4">READ</p>
              <p class="item-cell rf-relative rf-font-semibold">{{ JSON.parse(vehicle.meta_hardware).read_method.toUpperCase() }}</p>
            </div>
            <div class="rf-flex rf-flex-row rf-rounded-md rf-pr-1">
              <p class="rf-bg-[#0f4379] rf-px-1 rf-rounded-l-md rf-text-white rf-mr-4">ECU</p>
              <p class="item-cell rf-relative rf-font-semibold">{{ getCombinedECUDetails(vehicle) }}</p>
            </div>
          </div>
        </div>
        <div
          class="rf-absolute rf-flex rf-flex-row rf-flex-wrap rf-justify-between rf-max-w-[600px] screen1100:rf-max-w-full rf-left-[75px] rf-right-[75px] rf-z-10 rf-mx-[auto] rf-bg-[#ffffff] rf-px-3 rf-py-[1px] rf-rounded-b rf-shadow-md rf-font-semibold">
          <div class="rf-pr-3">
            <span class="rf-text-[#0f4379] rf-font-normal">Requested tuning:</span>
            <span class="rf-text-[#FF4800]">{{ vehicle.tuningDisplayName }}</span>
          </div>
          <div>
            <span class="rf-text-[#0f4379] rf-font-normal">Credits:</span>
            <span class="rf-text-[#FF4800]">{{ vehicle.credits }}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      vehicles: [],
      website_id: 4,
      date_from: "",
      date_until: "",
      limit: 6,
      todayData: null,
      loading: true,
      dateYesterday: "",
      dateUntil: "",
      fileLimit: "",
    };
  },
  created() {
    this.fetchData();
    this.startAutoRefresh();
  },
  methods: {
    async fetchData() {
      this.dateUntil = new Date().toISOString().split("T")[0];
      this.dateYesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];
      try {
        let params = {
          limit: 10,
        };
        let response = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});
        console.log("Files Count:", response.data.files.length);

        if (response.data.files.length === 0) {
          this.todayData = false;
          const params = {
            date_from: this.dateYesterday,
            date_until: this.dateUntil,
            limit: 10,
          };
          const responseData = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});
          this.vehicles = responseData.data.files;
        } else {
          this.todayData = true;
          const params = {
            limit: 10,
          };
          const responseData = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});
          this.vehicles = responseData.data.files;
        }
        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.vehicles = [];
      }
    },
    startAutoRefresh() {
      setInterval(() => {
        this.fetchData();
      }, 600000);
    },
    getBrandImageUrl(brand) {
      const formattedBrand = brand.toLowerCase().replace(/\s+/g, "-");
      return `https://www.ecufiles.com/assets/img/brand-logos/${formattedBrand}.webp`;
    },
    getCombinedVehicleName(vehicle) {
      const combinedName = vehicle.vehicle_data.brand + " " + vehicle.vehicle_data.series + " " + vehicle.vehicle_data.engine;

      return combinedName.length > 30 ? combinedName.slice(0, 30) + "..." : combinedName;
    },
    getCombinedECUDetails(vehicle) {
      const combinedName = JSON.parse(vehicle.meta_hardware).ecu_brand + " " + JSON.parse(vehicle.meta_hardware).ecu_build;

      return combinedName.length > 22 ? combinedName.slice(0, 22) + "..." : combinedName;
    },
  },
};
</script>
<style scoped>
.grid-cells {
  grid-template-columns: repeat(4, minmax(0, auto));
}

.item-cell::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 24px;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  background: #0f4379;
  bottom: 0;
  left: -16px;
}
</style>
